// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("/opt/build/repo/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-development-index-js": () => import("/opt/build/repo/src/pages/development/index.js" /* webpackChunkName: "component---src-pages-development-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-index-js": () => import("/opt/build/repo/src/pages/product/index.js" /* webpackChunkName: "component---src-pages-product-index-js" */),
  "component---src-pages-product-openforce-js": () => import("/opt/build/repo/src/pages/product/openforce.js" /* webpackChunkName: "component---src-pages-product-openforce-js" */),
  "component---src-pages-product-petsmart-js": () => import("/opt/build/repo/src/pages/product/petsmart.js" /* webpackChunkName: "component---src-pages-product-petsmart-js" */),
  "component---src-pages-product-prnetwork-js": () => import("/opt/build/repo/src/pages/product/prnetwork.js" /* webpackChunkName: "component---src-pages-product-prnetwork-js" */),
  "component---src-pages-product-wonderscience-js": () => import("/opt/build/repo/src/pages/product/wonderscience.js" /* webpackChunkName: "component---src-pages-product-wonderscience-js" */)
}

